
/*eslint-disable */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "./MDBox";
import MDInput from "./MDInput";
import { notifySuccess, notifyError } from "components/ToastifyCompo";
import { auth } from '../firebase/index';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";

const ChangePassword = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            currentpassword: '',
            newpassword: '',
            confirmpassword: '',
        },
        validationSchema: Yup.object({
            currentpassword: Yup.string().required('Current Password is required'),
            newpassword: Yup.string().min(8).required('New Password is required'),
            confirmpassword: Yup.string().required("Confirm Password is Required").oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            setLoading(true);

            try {
                const user = auth.currentUser;
                const credential = EmailAuthProvider.credential(user.email, values.currentpassword); // Use EmailAuthProvider from firebase/auth

                // Reauthenticate user
                await reauthenticateWithCredential(user, credential);

                // Update password
                await updatePassword(user, values.newpassword);

                // Reset form and show success notification
                formik.resetForm();
                notifySuccess('Password updated successfully!');
                onClose(); // Close the dialog
            } catch (err) {
                notifyError(`Error updating password invalid current password`);
            } finally {
                setLoading(false);
            }
        },
        
    });

    return (
        <Dialog open={open} onClose={onClose} sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                },
            },
        }}>
            <DialogTitle>Change Password</DialogTitle>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>

                <DialogContent>
                    <MDBox mb={2}>
                        <MDInput type="password" label="Current Password" fullWidth id={'currentpassword'} value={formik.values.currentpassword} onChange={formik.handleChange} />
                        {formik.touched.currentpassword && formik.errors.currentpassword ? (
                            <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.currentpassword}</p>
                        ) : ""}
                    </MDBox>

                    <MDBox mb={2}>
                        <MDInput type="password" label="New Password" fullWidth id={'newpassword'} value={formik.values.newpassword} onChange={formik.handleChange} />
                        {formik.touched.newpassword && formik.errors.newpassword ? (
                            <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.newpassword}</p>
                        ) : ""}
                    </MDBox>

                    <MDBox mb={2}>
                        <MDInput type="password" label="Confirm Password" fullWidth id={'confirmpassword'} value={formik.values.confirmpassword} onChange={formik.handleChange} />
                        {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                            <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.confirmpassword}</p>
                        ) : ""}
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button style={{ color: 'blue' }} type="submit" variant="outlined" disabled={loading}>
                        {loading ? 'Submitting...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </MDBox>
        </Dialog>
    );
}

export default ChangePassword;
