// src/components/LogoutIc.js
import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase/index";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Context } from "../context/userContext";
import useLogout from "hooks/useLogout";
import Icon from "@mui/material/Icon";



const LogoutIc = () => {
  const [open, setOpen] = useState(false);
  const { setIsAdmin } = useContext(Context);
  const logout = useLogout();

  const handleLogout = async () => {
    try {
      await logout();
      setIsAdmin(false);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    // handleOpen(); // Open the logout dialog when the component mounts
    return () => handleClose(); // Cleanup to close the dialog if the component unmounts
  }, []);

  return (
    <div>
      <div className="css-18ct1p7" style={{fontSize:"14px", marginLeft:"21px", width:"85%"}} onClick={()=>{setOpen(true)}}><Icon fontSize="small">logout</Icon><span style={{marginLeft:"15px"}}>Logout</span></div>
    <Dialog 
      open={open} 
      // onClose={handleClose} 
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here
          },
        },
      }}
    >
      <DialogTitle>Logout Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: 'blue' }} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleLogout} style={{ color: 'blue' }} variant="outlined">
          Logout
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default LogoutIc;
