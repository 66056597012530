/*eslint-disable */

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let successToastId = null;
let errorToastId = null;

const notifySuccess = (message) => {
  if (successToastId !== null) {
    toast.dismiss(successToastId);
  }
  successToastId = toast.success(message, {
    position:"top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    style: { textAlign: 'left',fontSize: '14px' } // Align text to the left
  });
};
    
const notifyError = (message) => {
  if (errorToastId !== null) {
    toast.dismiss(errorToastId);
  }
  errorToastId = toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    style: { textAlign: 'left',fontSize: '14px' } // Align text to the left
  });
};

const Toast = () => {
  const marginTopValue = '60px';

  return (
    <ToastContainer
    position="top-center"
    autoClose={2000} // Auto close the toast after 5 seconds
      hideProgressBar={true}
      closeOnClick
      pauseOnHover
      style={{ marginTop: marginTopValue }}

    />
  );
};

export { notifySuccess, notifyError }; // Export the functions here
export default Toast;
