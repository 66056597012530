/*eslint-disable */
import { useState, useEffect, useMemo, useContext } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { auth } from "./firebase";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
// RTL plugins
// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Toast from "components/ToastifyCompo";
import { onAuthStateChanged } from "firebase/auth";
import { Context } from "context/userContext";
import { getMe } from "api/user";
import { notifyError } from "components/ToastifyCompo";
import Dialogue from "components/Dialogue";
import BackdropLoader from "components/BackDropLoader";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [user, setUser] = useState(null);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [admin, setIsAdmin] = useState(null);
  const [emailContent,setEmailContent] = useState("")
  const [subject,setSubject] = useState("")
  const [to,setTo] = useState("")
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Determine if the current route is for authentication
  const isAuthRoute = useMemo(() => pathname.startsWith('/authentication/'), [pathname]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          setEmailVerified(user.emailVerified);
          const userData = await getMe(user.email);
          if (userData.status) {
            setUser(userData.data);
            const idTokenResult = await user.getIdTokenResult();
            const { claims } = idTokenResult;
            setIsAdmin(claims.admin || false);
          } else {
            notifyError("UNABLE TO FETCH USER");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          notifyError("Failed to fetch user data");
        }
      } else {
        setUser(null);
        setIsAdmin(null);
        if (!isAuthRoute) {
          navigate("/authentication/sign-in");
        }
      }
    });

    return () => unsubscribe(); // Cleanup function to unsubscribe from the auth state listener
  }, [navigate, isAuthRoute]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "emailVerified") {
        const emailVerified = auth.currentUser.emailVerified ? "1" : "0";
        if (localStorage.getItem('emailVerified') !== emailVerified) {
          localStorage.setItem("emailVerified", emailVerified);
          window.location.reload();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  if (admin === null && !isAuthRoute) {
    return <BackdropLoader open={true} />;
  }

  return (
    <Context.Provider value={{ to,setTo,emailContent,setEmailContent,subject,setSubject,openDialogue, setOpenDialogue, isEmailVerified, setEmailVerified, user, setUser }}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {!isAuthRoute && layout === "dashboard" && (
          <>
            <Dialogue />
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="monetise-d"
              routes={admin == null ? routes : routes.filter(fil => fil.isAdmin == admin)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        <Routes>
          {getRoutes(admin == null ? routes : routes.filter(fil => fil.isAdmin == admin))}
          <Route path="*" element={<Navigate to={admin ? "/dashboard" : "/calculatorpacks"} />} />
        </Routes>
        <Toast />
      </ThemeProvider>
    </Context.Provider>
  );
}
/*eslint-disable */
