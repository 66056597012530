/*eslint-disable */

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { auth } from "../../../firebase/index"

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Industrydata } from "Data/Data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { furtherIndustry } from "Data/Data";
import { addUserRecord } from "api/user";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { notifyError } from "components/ToastifyCompo";
import { Region } from "Data/Data";
import { sendVerifyEmail } from "api/user";
import { notifySuccess } from "components/ToastifyCompo";
import useMediaQuery from "hooks/MediaQuery";

function Basic() {
  const isMobile = useMediaQuery('(min-width: 950px)');
  const navigate = useNavigate()
  const [samebilling, setSameBilling] = useState(false);
  const handleSetRememberMe = () => setSameBilling(!samebilling);
  const [industry, setIndustry] = useState('');
  const [loading, setLoading] = useState(false)
  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      // CorporateDetails
      apt: '',
      address: '',
      abn_no: '',
      company_name: '',
      company_trading: '',
      industry: '',
      website: '',
      region: '',
      // PrimaryContact
      name: '',
      email: '',
      phone: '',
      mobile: '',
      // BillingContact
      billingname: '',
      billingemail: '',
      billingphone: '',
      billingmobile: '',
      subpack: '',
      password: '',
      cpassword: ''
    },
    validationSchema: Yup.object({
      // CorporateDetails
      apt: Yup.string(),
      address: Yup.string().required('address is required'),
      abn_no: Yup.string().required('ABN number is required'),
      company_name: Yup.string().required('Company Name is required'),
      company_trading: Yup.string().required('Company Trading Name is required'),
      industry: Yup.string().required('Industry is required'),
      subpack: Yup.string().required('Classification is required'),
      region: Yup.string().required('Region is required'),
      website: Yup.string()
        .required('Website is required')
        .url('Invalid website URL'),
      // PrimaryContact
      name: Yup.string().required('Name is required'),
      email: Yup.string().email().required('Email is required'),
      phone: Yup.string().required('Phone Number is required'),
      mobile: Yup.string().required('Mobile Number is required'),
      // BillingContact
      billingname: samebilling ? Yup.string() : Yup.string().required('Name is required'),
      billingemail: samebilling ? Yup.string() : Yup.string().email('Invalid email').required('Email is required'),
      billingphone: samebilling ? Yup.string() : Yup.string().required('Phone Number is required'),
      billingmobile: samebilling ? Yup.string() : Yup.string().required('Mobile Number is required'),
      password: Yup.string().required('Password is required'),
      cpassword: Yup.string().required("Confirm Password is Required").oneOf([Yup.ref('password'), null], 'Passwords must match'),

    }),
    onSubmit: (values) => {
      console.log(values)
      if (values.industry === "Accountants") {
        if (values.region === "AUS") {
          values.industry = "Accountants-Aus";
        } else if (values.region === "NZ") {
          values.industry = "Accountants-Nz";
        } else {
          values.industry = "Accountants-ALL";
        }
    }
      setLoading(true)
      const data = {
        "fullName": values.name,
        "email": values.email,
        "purchaseTried":false,
        "primaryContact": {
          "fullName": values.name,
          "email": values.email,
          "phoneNumber": values.phone,
          "mobileNumber": values.mobile
        },
        "billingContact": {
          "fullName": values.billingname,
          "email": values.billingemail,
          "phoneNumber": values.billingphone,
          "mobileNumber": values.billingmobile
        },
        "stripe":null,
        "billingSameAsPrimary": samebilling,
        "Apt": values.apt,
        "businessAddress": values.address,
        "AbnNumber": values.abn_no,
        "legalName": values.company_name,
        "tradingName": values.company_trading,
        "industry": {
          "industry": values.industry,
          "subPack": values.subpack
        },
        "website": values.website,
        "region": values.region
      }
      createUserWithEmailAndPassword(auth, values.email, values.password).then( record => {
        addUserRecord(data).then(async e => {
          console.log(e)

          if (e.status) {
            setLoading(false)
            sendVerifyEmail(values.email).then(async(e) => {
              if (e.status) {
                notifySuccess("Verification Email Sent Successfully")
              } else {
                notifyError("Something Wrong")
              }
            })
             await signOut(auth)
            setLoading(false)
            navigate("authentication/sign-in")

          }
        }).catch(error => {
          notifyError(error.message
          )
          setLoading(false)

        })
      }).catch(error => {
        notifyError(error.message)
        setLoading(false)

      })
      // navigate('/authentication/sign-in')
    },
  });
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      {isMobile &&
        <div style={{ width: '35%', height: '100vh', position: 'fixed', backgroundColor: '#004051', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
          <MDTypography variant="h1" fontWeight="medium" color="white" mt={1}>
            LOGO
          </MDTypography>
        </div>

      }
      <div style={{width:isMobile ? '60%' : '90%', marginLeft:isMobile ? '40%' : '5%', overflowY: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
          <Card style={{ width:isMobile ? '80%' : '100%', marginTop: '10%', marginBottom: '20px' }}>
            <MDBox
              variant="gradient"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              style={{ backgroundColor: '#14a4a4' }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Create an Account
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                <MDTypography variant="h4" fontWeight="medium" color="black">
                  Corporate Details
                </MDTypography>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row' }} mt={1}>
                  <MDBox style={{ width: '40%', marginRight: '10px' }}>
                    <MDInput type="text" label="Apt/Suite/Floor" id={'apt'} value={formik.values.apt} onChange={formik.handleChange} />
                    {formik.touched.apt && formik.errors.apt ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.apt}</p>
                    ) : ""}
                  </MDBox>
                  <MDBox style={{ width: '80%' }}>
                    <MDInput type="text" label="Business Address" fullWidth id={'address'} value={formik.values.address} onChange={formik.handleChange} />
                    {formik.touched.address && formik.errors.address ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.address}</p>
                    ) : ""}
                  </MDBox>
                </MDBox>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} mt={1}>
                  <div style={{ flex: 1 }}>
                    <MDInput type="number" label="Company ABN number" id={'abn_no'} style={{ width: '100%' }} value={formik.values.abn_no} onChange={formik.handleChange} />
                    {formik.touched.abn_no && formik.errors.abn_no ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.abn_no}</p>
                    ) : ""}
                  </div>
                  <div style={{ flex: 1 }}>
                    <MDInput type="text" label="Legal name of company" style={{ width: '100%' }} id={'company_name'} value={formik.values.company_name} onChange={formik.handleChange} />
                    {formik.touched.company_name && formik.errors.company_name ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.company_name}</p>
                    ) : ""}
                  </div>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="text" label="Company trading name" fullWidth id={'company_trading'} value={formik.values.company_trading} onChange={formik.handleChange} />
                </MDBox>
                {formik.touched.company_trading && formik.errors.company_trading ? (
                  <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.company_trading}</p>
                ) : ""}
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row' }} mt={1}>
                  <FormControl style={{ flex: 1 }}>
                    <InputLabel id="job-role-label">Region</InputLabel>
                    <Select
                      labelId="job-role-label"
                      label="Region"
                      sx={{ height: '44px', width: '90%' }}
                      id={'industry'} value={formik.values.region} onChange={(e) => {
                        console.log(e.target.value)
                        formik.handleChange(e)
                      }}
                      name='region'
                    >
                      {Region.map((data) => {
                        return (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        )
                      })}
                    </Select>
                    {formik.touched.region && formik.errors.region ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.region}</p>
                    ) : ""}
                  </FormControl>

                  <div style={{ flex: 1 }}>
                    <MDInput type="text" label="Website" style={{ width: '100%' }} id={'website'} value={formik.values.website} onChange={formik.handleChange} />
                    {formik.touched.website && formik.errors.website ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.website}</p>
                    ) : ""}
                  </div>
                </MDBox>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row' }} mt={1}>

                  <FormControl style={{ flex: 1 }}>
                    <InputLabel id="job-role-label">Industry</InputLabel>
                    <Select
                      labelId="job-role-label"
                      label="Industry"
                      sx={{ height: '44px', width: '90%' }}
                      id={'industry'} value={formik.values.industry} onChange={(e) => {
                        console.log(e.target.value)
                        formik.handleChange(e)
                      }}
                      name='industry'
                    >
                      {Industrydata.filter(f => f.region.includes(formik.values.region)).map((data) => {

                        return (
                          <MenuItem value={data.value}>{data.label}</MenuItem>
                        )
                      })}
                    </Select>
                    {formik.touched.industry && formik.errors.industry ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.industry}</p>
                    ) : ""}
                  </FormControl>
                  <FormControl style={{ flex: 1 }}>
                    {furtherIndustry.filter(fil => fil.industry == formik.values.industry).length > 0 &&
                      <>
                        <InputLabel id="job-role-label">Calssification</InputLabel>
                        <Select
                          labelId="job-role-label"
                          label="Calssification"
                          sx={{ height: '44px', width: '100%' }}
                          id={'subpack'} value={formik.values.subpack} onChange={formik.handleChange}
                          name='subpack'
                        >
                          {furtherIndustry.filter(fil => fil.industry == formik.values.industry)[0].options.filter(opt => opt.region.includes(formik.values.region)).map((data) => {
                            return (
                              <MenuItem value={data.value}>{data.label}</MenuItem>
                            )
                          })}
                        </Select>
                        {formik.touched.subpack && formik.errors.subpack ? (
                          <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.subpack}</p>
                        ) : ""}
                      </>
                    }
                  </FormControl>
                </MDBox>




                <MDTypography variant="h4" fontWeight="medium" color="black">
                  Primary Contact
                </MDTypography>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} mt={1}>
                  <div style={{ flex: 1 }}>
                    <MDInput type="text" label="Full Name" id={'name'} style={{ width: '100%' }} value={formik.values.name} onChange={formik.handleChange} />
                    {formik.touched.name && formik.errors.name ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.name}</p>
                    ) : ""}
                  </div>
                  <div style={{ flex: 1 }}>
                    <MDInput type="text" label="Email" style={{ width: '100%' }} id={'email'} value={formik.values.email} onChange={formik.handleChange} />
                    {formik.touched.email && formik.errors.email ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.email}</p>
                    ) : ""}
                  </div>
                </MDBox>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} mt={1}>
                  <div style={{ flex: 1 }}>
                    <MDInput type="number" label="Phone Number" id={'phone'} style={{ width: '100%' }} value={formik.values.phone} onChange={formik.handleChange} />
                    {formik.touched.phone && formik.errors.phone ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.phone}</p>
                    ) : ""}
                  </div>
                  <div style={{ flex: 1 }}>
                    <MDInput type="number" label="Mobile Number" style={{ width: '100%' }} id={'mobile'} value={formik.values.mobile} onChange={formik.handleChange} />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.mobile}</p>
                    ) : ""}
                  </div>
                </MDBox>
                <MDTypography variant="h4" fontWeight="medium" color="black">
                  Billing Contact
                </MDTypography>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox checked={samebilling} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Same as primary contact
                  </MDTypography>
                </MDBox>
                {samebilling == false &&
                  <>
                    <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} mt={1}>
                      <div style={{ flex: 1 }}>
                        <MDInput type="text" label="Full Name" id={'billingname'} style={{ width: '100%' }} value={formik.values.billingname} onChange={formik.handleChange} />
                        {formik.touched.billingname && formik.errors.billingname ? (
                          <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.billingname}</p>
                        ) : ""}
                      </div>
                      <div style={{ flex: 1 }}>
                        <MDInput type="text" label="Email" style={{ width: '100%' }} id={'billingemail'} value={formik.values.billingemail} onChange={formik.handleChange} />
                        {formik.touched.billingemail && formik.errors.billingemail ? (
                          <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.billingemail}</p>
                        ) : ""}
                      </div>
                    </MDBox>
                    <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} mt={1}>
                      <div style={{ flex: 1 }}>
                        <MDInput type="number" label="Phone Number" id={'billingphone'} style={{ width: '100%' }} value={formik.values.billingphone} onChange={formik.handleChange} />
                        {formik.touched.billingphone && formik.errors.billingphone ? (
                          <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.billingphone}</p>
                        ) : ""}
                      </div>
                      <div style={{ flex: 1 }}>
                        <MDInput type="number" label="Mobile Number" style={{ width: '100%' }} id={'billingmobile'} value={formik.values.billingmobile} onChange={formik.handleChange} />
                        {formik.touched.billingmobile && formik.errors.billingmobile ? (
                          <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.billingmobile}</p>
                        ) : ""}
                      </div>
                    </MDBox>
                  </>
                }
                <MDTypography variant="h4" fontWeight="medium" color="black">
                  Password
                </MDTypography>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} mt={1}>
                  <div style={{ flex: 1 }}>
                    <MDInput type="password" label="Password" fullWidth id={'password'} value={formik.values.password} onChange={formik.handleChange} />
                    {formik.touched.password && formik.errors.password ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.password}</p>
                    ) : ""}
                  </div>
                  <div style={{ flex: 1 }}>
                    <MDInput type="password" label="Confirm Password" fullWidth id={'cpassword'} value={formik.values.cpassword} onChange={formik.handleChange} />
                    {formik.touched.cpassword && formik.errors.cpassword ? (
                      <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.cpassword}</p>
                    ) : ""}
                  </div>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton loading={loading} variant="gradient" color="info" fullWidth type="submit">
                    {loading ? 'Signing up....' : 'sign up'}
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign in
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Basic;
