/*eslint-disable */

import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { Allpacks } from "Data/Data";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { notifySuccess } from "components/ToastifyCompo";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetUsers } from "api/user";
import { format, parse } from 'date-fns';
import CustomizedProgressBars from "components/Loader";
import AllUsersModal from "examples/Modals/AllUsersModal/AllUsersModal";


const Allusers = () => {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData]=useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        GetUsers().then((e) => {
            console.log(e, "e.data")
            setData(e)
            setFilteredData(e)
            setLoad(false)
        })
    }, [])
    const navigate = useNavigate()

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}   
            </MDTypography>
        </MDBox>
    );

    const columns = [
        { Header: "Id", accessor: "id", align: "left", },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Verify", accessor: "verify", align: "left" },
        { Header: "Date", accessor: "date", align: "center" },
    ]
    const rows = filteredData.map((inv, index) => ({
        id: <Job title={index + 1} />,
        email: <Job title={inv.email} />,
        verify: <Job title={inv.emailVerified === true ? 'Verified' : 'Not Verified'} />,
        date: <Job title={inv.metadata.creationTime} />


    }));
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
                <MDTypography variant="h6" color="white">
                    All Users
                </MDTypography>
                <AllUsersModal title="Filters" data={data} setFilteredData={setFilteredData} buttonName1="Search" buttonName2="Cancel"/>
            </MDBox>
            {load ? <CustomizedProgressBars /> :
                <MDBox mt={4} mb={3} mx={1}
                    py={2}  
                    px={2}>
                    <MDBox pt={1}>
                        <DataTable   
                            table={{ columns: columns, rows: rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </MDBox>
                </MDBox>
            }  
        </DashboardLayout>
    )
}
export default Allusers;
