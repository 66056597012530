/*eslint-disable */

import { Typography } from "@mui/material";
import React from "react";
import CheckIcon from '@mui/icons-material/Check';

const CheckText = ({ text }) => {
    return (
        <div style={{display:'flex',flexDirection:'row',marginTop:'5%',marginLeft:'4%'}}>
        <CheckIcon style={{color:'green',fontSize:'15px',marginRight:'10px'}}/>
        <Typography variant="body2" component="p" color="text.main" >
            {text}
        </Typography>
        </div>
    )
}
export default CheckText;