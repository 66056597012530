const { createContext } = require("react");

export const Context = createContext({
    user:null,
    setUser:auth=>{},
    isEmailVerified:false,
    setEmailVerified:auth=>{},
    openDialogue:false,
    setOpenDialogue:auth=>{},
    isAdmin:null,
    setIsAdmin:auth=>{},
    to:null,
    setTo:auth=>{},
    emailContent:null,
    setEmailContent:auth=>{},
    subject:null,
    setSubject:auth=>{}
})

export const BASE_URL = 
process.env.NODE_ENV == "development" ? 
"http://localhost:9100"         
:
"https://monetise-d-7bfcbe7273db.herokuapp.com"
