/*eslint-disable */

import { Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Allpacks } from "Data/Data";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { notifySuccess } from "components/ToastifyCompo";
import { Context } from "context/userContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext, useState } from "react";


const Apikey = () => {
    const { user,isEmailVerified} = useContext(Context);
    const [showApiKey, setShowApiKey]=useState(false);
    // console.log(user)
    const generateRandomApiKey = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };              
    const [apiKey, setApiKey] = React.useState(() => generateRandomApiKey());
    const copyToClipboard = (apiKey) => {
        navigator.clipboard.writeText(apiKey).then(() => {
            console.log("apikey", apiKey)
            notifySuccess("API Key copied to clipboard!"); // Optionally, replace with a more subtle notification
        });
    };
    
    const revealApiKey = ()=>{
        setShowApiKey(!showApiKey);
    }
    
    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
        </MDBox>
    );                              
    const columns = [
        { Header: "Title", accessor: "Title", align: "center", },
        { Header: "Api Key", accessor: "Apikey", align: "center" },
        { Header: "action", accessor: "action", align: "center" },

    ]   
    const rows = user && user.permissions ?

        [   
            {
                Title: <Job title={'Api Key'} />,
                Apikey: <Job title={showApiKey? user.permissions.apiKey.toUpperCase() :'* * * * * * *'} />,
                action: (
                    <>
                        <Button
                            onClick={() => copyToClipboard(user.permissions.apiKey)}
                            sx={{
                                backgroundColor: "grey",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#14a4a4",
                                    color: "#fff",

                                }
                            }}
                        >
                            Copy
                        </Button>
                        <Button
                            onClick={revealApiKey}
                            sx={{
                                backgroundColor: "grey",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "#14a4a4",
                                    color: "#fff",

                                }, marginLeft:"10px"
                            }}
                        >
                            {showApiKey? "Hide Key":"Reveal Key"}
                        </Button>
                    </>
                ),
            }
        ] : []
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    API key
                </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={3} mx={1}
                py={2}
                px={2}
                style={{
                    filter: !isEmailVerified ? 'blur(2px)' : 'none',
                    pointerEvents: ! isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                }}
                >
                <MDBox pt={1}>
                    <DataTable
                        table={{ columns: columns, rows: rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox> 
        </DashboardLayout>
    )    
}
export default Apikey;