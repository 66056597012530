import axios from "axios";
import { BASE_URL } from "context/userContext";
const URL = BASE_URL+'/admin/'

export  async function getFollowUp (){
try {
    const response =  await axios.get(URL+"follow-up")
    return response.data
} catch (error) {
    return error.response.data
}
}
// personalised/send

export  async function sendPersonalisedEmail (body){
    try {
        const response =  await axios.post(URL+"personalised/send",body)
        return response.data
    } catch (error) {
        return error.response.data
    }
    }