import axios from "axios";
import { BASE_URL } from "context/userContext";
const URL = BASE_URL+'/packs/get'
export async function getPacks(region,industry){
    try {
        const response = await axios.get(URL+`?region=${region}&industry=${industry}`)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
// http://localhost:9100/packs/get/new?industry=Financial Institutes&category=credit union
export async function getPacksNew(industry,category){
    try {
        console.log(industry,category.toLowerCase(),"new packsss")
        const response = await axios.get(URL+`/new?industry=${industry}&category=${category.toLowerCase()}`)
        return response.data
    } catch (error) {
        return error.response.data
    }
}