/*eslint-disable */

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import OtpInput from 'react-otp-input';
import useMediaQuery from "hooks/MediaQuery";
import './OtpInput.css'; // Import a CSS file for styling

function Basic() {
    const isMobile = useMediaQuery('(min-width: 950px)');    
    const navigate = useNavigate()   
    const [loading, setLoading] = useState(false)   
    const location = useLocation();
    const { email } = location.state;
    const [seconds, setSeconds] = useState(30);
    useEffect(() => {
        // Exit the effect if seconds reach 0
        if (seconds <= 0) return;

        // Set up the interval to decrement the seconds
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        // Clean up the interval on component unmount or when seconds reach 0
        return () => clearInterval(intervalId);
    }, [seconds])

    const [otp, setOtp] = useState('')
    const handleChange = (newValue) => {
        if (newValue === '' || /^[0-9\b]+$/.test(newValue)) {
            setOtp(newValue)
        }
    }
    const handleSubmit = () => {
        navigate('/authentication/change-password')
    }
    return (
        <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '40%', height: '100vh', backgroundColor: '#004051', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                <MDTypography variant="h1" fontWeight="medium" color="white" mt={1}>
                    LOGO
                </MDTypography>
            </div>
            <div style={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card style={{ width: '60%', margin: 'auto' }}>
                    <MDBox
                        variant="gradient"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                        style={{ backgroundColor: '#14a4a4' }}

                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Enter OTP
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        <MDTypography>We send a code to {email}</MDTypography>
                        <MDBox mt={4}>
                            <OtpInput
                                value={otp}
                                onChange={handleChange}
                                numInputs={6}
                                shouldAutoFocus={true}
                                containerStyle="otpContainer"
                                inputStyle="otpInput"
                                isInputNum={true}
                                renderInput={(props) => <input  {...props} style={{ width: isMobile ? '45px' : '35px', height: isMobile ? '45px' : '35px', borderRadius: '10px', border: '3px solid #EAEAEF' }} />}

                            />
                            <MDBox mt={4} mb={1}>
                                <MDButton loading={loading} variant="gradient" color="info" onClick={handleSubmit} fullWidth type="submit">
                                {loading ? 'Submitting...' : 'Submit'}
                                </MDButton>
                            </MDBox>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Did&apos;t received code?{" "}
                                <MDTypography
                                    component={Link}
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient

                                >
                                    Send Again
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>

                </Card>
            </div>
        </div>
    );
}

export default Basic;
