import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Context } from '../context/userContext';
import { notifyError, notifySuccess } from './ToastifyCompo';
import { sendVerifyEmail } from 'api/user';

      
const Dialogue = () => {
    const {user,openDialogue , setOpenDialogue} = useContext(Context)
    const [load, setLoad] = useState(false)
       
    const handleVerify = ()=>{
        setLoad(true)  
        sendVerifyEmail(user.email).then(e=>{
            notifySuccess("Email has been successfully sent.")         
        }).catch(err=>          
        {     
                notifyError("Something went wrong. Please try again in awhile.")
                console.log(user.email,err)
            })
            setLoad(false)
            setOpenDialogue(false)
    }   
  return (
    <>
     <Dialog
                hideBackdrop
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={openDialogue} onClose={() => setOpenDialogue(false)} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>Email Not Verified</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your email is not verified yet. Please verify your email to proceed
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleVerify} style={{ color: 'blue' }} variant="outlined">
                       {load?"Sending...": "Verify My Email"}
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default Dialogue