/*eslint-disable */

import { Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const CrossText = ({ text }) => {
    return (
        <div style={{display:'flex',flexDirection:'row',marginTop:'5%',marginLeft:'4%'}}>
        <CloseIcon style={{color:'red',fontSize:'15px',marginRight:'10px'}}/>
        <Typography variant="body2" component="p" color="text.main" style={{ textDecoration: 'line-through' }}>
            {text}
        </Typography>
        </div>
    )
}
export default CrossText;