/*eslint-disable */

import { Button, Card, CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetSubscription } from "api/user";


const Subscriptions = () => {
    const [data,setData] = useState([])
    useEffect(() => {
        GetSubscription().then(e => {
            setData(e.data)
        })
    },[])
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [load, setLoad] = useState(false)

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
        </MDBox>
    );
    const columns = [
        { Header: "ID", accessor: "id", align: "left", },
        { Header: "Name", accessor: "name", align: "left", },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "TradingName", accessor: "tradingName", align: "left" },
        { Header: "Calculators", accessor: "calculators", align: "left" },

    ]

    const rows = data.map((inv,index) => ({  

        id: <Job title={index + 1} />,
        name: <Job title={inv.data.fullName} />,
        email: <Job title={inv.data.email} />,
        tradingName: <Job title={inv.data.tradingName} />,
        calculators: <Job title = {inv.data.permissions && inv.data.permissions.ticks.map((item)=>{return <Chip style={{marginRight: "5px"}} color="secondary" label={item}/>})}/>

    }));    

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Dialog
                hideBackdrop
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open} onClose={() => setOpen(false)} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>Cancel Subscription Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to cancel your active subscription?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={load} onClick={() => setOpen(false)} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button style={{ color: 'blue' }} variant="outlined">
                        {load ? "please wait.." : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"  
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
                <MDTypography variant="h6" color="white">
                    Subscriptions   
                </MDTypography>
            </MDBox>   
            <MDBox mt={4} mb={3} mx={1}
                py={2}
                px={2}>
                <MDBox pt={1}>   
                    <DataTable
                        table={{ columns: columns, rows: rows }}
                        isSorted={false}
                        entriesPerPage={false}  
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}
export default Subscriptions;
