/*eslint-disable */

import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { Allpacks } from "Data/Data";
import { createSubscription, getSession, getSubscription, upgradeSubscription } from "../../api/stripe";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CheckText from "components/Packagecompo/CheckText";
import CrossText from "components/Packagecompo/CrossText";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/userContext";
import { Navigate, useNavigate } from "react-router-dom";
import { notifyError } from "components/ToastifyCompo";
import { getPacks } from "api/packs";
import { getPacksNew } from "api/packs";
import { auth } from "../../firebase/index";
import { furtherIndustry } from "Data/Data";
import { Region } from "Data/Data";
import { Industrydata } from "Data/Data";
import { updateUserInfo } from "api/user";
import useMediaQuery from "hooks/MediaQuery";
import { updateHasClicked } from "api/user";

const Packs = () => {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const { user, isEmailVerified } = useContext(Context)
    const [packs, setPacks] = useState([])
    const [subscription, setSubscription] = useState(null)
    const { setOpenDialogue } = useContext(Context)
    const navigate = useNavigate()
    const url = window.location.href; // Example URL: https://example.com/page?session_id=123456
    const params = new URLSearchParams(new URL(url).search);
    const session_id = params.get("session_id")
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [newPack, setNewPack] = useState([])
    const [open, setOpen] = useState(false)
    const [region, setRegion] = useState("")
    const [industry, setInndustry] = useState("")
    const [classf, setClassf] = useState("")
    const [index, setIndex] = useState(0)
    const items = [
        {
            id: 1,
            title: "Configure your Calculator styles",
            bodyText: " You have not added your personalizations yet. Please go to settings section and add your disclamier. You can update them anytime.",
            navigate: "/settings",
            goText: "Go to settings",
            cancelText: "Cancel"
        },
        {
            id: 2,
            title: "Oops! Unable to complete subscription.",
            bodyText: "Your subscription request could not be processed at this time. Please contact support for assistance.",
            navigate: "/settings",
            goText: "Contact Support",
            cancelText: "Cancel"
        }
    ]
    useEffect(() => {
        // console.log(user, "industryd")

        if (session_id && user) {
            getSession(session_id, user.email).then(e => {
                if (e.status) {
                    setRefresh(!refresh)
                    navigate("/calculatorpacks")
                }
                else {
                    notifyError("SOMETHING WENT WRONG")
                }
            })
        }
    }, [user])

    useEffect(() => {
        // console.log(user, packs.length, "subscriptinon")
        if (user && newPack.length > 0) { // Only fetch subscription if packs data is available
            setLoading(true);
            getSubscription(user.email).then(e => {
                if (e.status && e.user.stripe) {
                    setSubscription(newPack.filter(ti => ti.price_id === e.user.stripe.price_id).map(l => {
                        l.subscription = e.user.stripe.subscription;
                        return l;
                    }));
                    // console.log(subscription, "subscriptinon")
                }
                setLoading(false);
            })
        }
    }, [user, newPack, refresh]);
    useEffect(() => {
        if (user) {
            setLoading(true);
            setClassf(user.industry.subPack)
            setInndustry(user.industry.industry)
            setRegion(user.region)
            getPacksNew(user.industry && user.industry.industry, user.industry && user.industry.subPack).then(e => {
                setNewPack(e.data)
                // console.log(e.data, industry, classf, region, "new packss")
                e.data.map(m => {
                    const region = m.region.filter(fil => fil.abbr == "AUS")[0]
                })
            })
            setLoading(false);

        }
    }, [user, refresh])

  


    const getSubscriptionLink = (priceId) => {
        if (auth.currentUser.emailVerified) {
            if (!user.personalised || Object.keys(user.personalised).length === 0 || !user.personalised.note || Object.keys(user.personalised.note).length === 0) return setOpen(true)
               
                if (user.cu_id && user.permissions === null) {
                    // console.log("inside IF")
                    setIndex(1);
                    setOpen(true)
                  }
                  else{
                    // console.log("inside Else")
                    UpdateClicked();
                    createSubscription(priceId, user.email).then(e => {
                        window.location.href = e
                    }).catch(err => {
                        console.log(err, "subscription error")
                    })
                  }
               
        }
        else {
            setOpenDialogue(true)
        }
    }

    const UpdateClicked = () => {
        updateHasClicked(user.email).then((e) => {
            if (e.status) {
                console.log('True Clicked')
            } else {
                console.log('False Clicked')
            }
        })
    }

    const handleButtonClick = (priceId) => {
        getSubscriptionLink(priceId);

    }

    const updateSubscription = (subscription, price) => {
        setLoading(true)
        upgradeSubscription(subscription, price, user.email).then(e => {
            if (e.status) {

                window.location.href = e
                // console.log(e, subscription, price, user.email)
            }
            else {
                setLoading(false)
                notifyError("Cannot upgrade your package.")
            }

        })
    }

    const updateInfo = () => {
        const selectedValue = industry;
        let updatedIndustry = selectedValue;
        if (selectedValue === "Accountants") {
            if (region === "AUS") {
                updatedIndustry = "Accountants-Aus";
            } else if (region === "NZ") {
                updatedIndustry = "Accountants-Nz";
            } else {
                updatedIndustry = "Accountants-ALL";
            }
        }
        updateUserInfo({ region: region, industry: updatedIndustry, subPack: classf, email: user.email }).then(e => {
            // console.log(e)
            setLoading(true)
            window.location.reload()
        }).catch(error => alert("Unable to update"))
    }
    return (
        <DashboardLayout>
            <Dialog
                hideBackdrop
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open} onClose={() => setOpen(false)} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>{items[index].title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {items[index].bodyText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} style={{ color: 'blue' }} variant="outlined">
                    {items[index].cancelText}
                    </Button>
                    <Button onClick={() => navigate(items[index].navigate)} style={{ color: 'blue' }} variant="outlined">
                    {items[index].goText}
                    </Button>
                </DialogActions>
            </Dialog>
            <DashboardNavbar />
            {user && user.email == "n.dharan@loanshub.com.au" || user && user.email == "ks7844201@gmail.com" ? <>
                <MDBox
                    mx={2}
                    mt={2}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
               {/* <iframe src="https://monetise-d-7bfcbe7273db.herokuapp.com/australian-capital-gains-tax-calculator?token=65d82ead-eb38-41f5-a163-fb9a3fd9f466" style="width:100%;  border:none;" frameborder="0" allowfullscreen></iframe> */}
                    <MDTypography variant="h6" color="white">
                        Calculators Packs
                    </MDTypography>
                </MDBox>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row' }} mt={1}>
                    <FormControl style={{ flex: 1 }}>
                        <InputLabel id="job-role-label">Region</InputLabel>
                        <Select
                            labelId="job-role-label"
                            label="Region"
                            sx={{ height: '44px', width: '90%' }}
                            id={'region'}
                            value={region}

                            onChange={(e) => {
                                setRegion(e.target.value)
                            }}
                            name='region'
                        >
                            {Region.map((data) => {
                                return (
                                    <MenuItem value={data.value}>{data.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </MDBox>
                <MDBox mb={2} style={{ display: 'flex', flexDirection: 'row' }} mt={1}>

                    <FormControl style={{ flex: 1 }}>
                        <InputLabel id="job-role-label">Industry</InputLabel>
                        <Select
                            labelId="job-role-label"
                            label="Industry"
                            sx={{ height: '44px', width: '90%' }}
                            id={'industry'}
                            value={industry?.split('-')[0]}
                            onChange={(e) => {
                                setInndustry(e.target.value);

                            }}
                            name='industry'
                        >
                            {Industrydata.filter(f => f.region.includes(region)).map((data) => {
                                return (
                                    <MenuItem value={data.value}>{data.label}</MenuItem>
                                )
                            })}
                        </Select>
                        <Button onClick={updateInfo}>Change</Button>
                    </FormControl>
                    <FormControl style={{ flex: 1 }}>
                        {furtherIndustry.filter(fil => fil.industry == industry).length > 0 &&
                            <>
                                <InputLabel id="job-role-label">Calssification</InputLabel>
                                <Select
                                    labelId="job-role-label"
                                    label="Calssification"
                                    sx={{ height: '44px', width: '100%' }}
                                    id={'subpack'} value={classf} onChange={(e) => setClassf(e.target.value)}
                                    name='subpack'
                                >
                                    {furtherIndustry.filter(fil => fil.industry == industry)[0].options.filter(opt => opt.region.includes(region)).map((data) => {
                                        return (
                                            <MenuItem value={data.value}>{data.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </>
                        }
                    </FormControl>
                </MDBox>
            </>
                : ""}
            <MDBox mt={8} mb={3}>
                {loading ? (
                    <Grid container spacing={2} ml={1}>
                        {[...Array(2)].map((_, index) => (
                            <Card key={index} sx={{ width: isMobile ? '47%' : '90%', marginRight: isMobile ? '15px' : '0px', marginBottom: '20px' }} mb={3}>
                                <CardContent>
                                    <Skeleton variant="rectangular" width="100%" height={200} />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton width="50%" />
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                ) : (
                    <Grid container spacing={2} ml={1}>
                        {newPack.map((tier, ind) =>
                            tier.region.filter(fil => fil.abbr == user.region).map((region) =>
                            (

                                <Card
                                    sx={{ width: isMobile ? '47%' : '90%', marginRight: isMobile ? '15px' : '0px', marginBottom: '30px' }}
                                    mb={3}
                                    style={{
                                        filter: !isEmailVerified ? 'blur(5px)' : 'none',
                                        pointerEvents: !isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                                    }}
                                >

                                    <CardContent>
                                        <MDBox
                                            variant="gradient"
                                            borderRadius="lg"
                                            coloredShadow="info"
                                            mx={2}
                                            mt={-3}
                                            p={2}
                                            mb={2}
                                            textAlign="center"
                                            style={{ backgroundColor: '#004051' }}

                                        >
                                            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                                {ind == 0 ? "Essential" : "Premium"} Package ${region.price.toLocaleString()}
                                            </MDTypography>
                                        </MDBox>
                                        {tier.ticks.map((tick) => <CheckText text={tick} />)}
                                        {tier.cross.map(cross => (<CrossText text={cross} />))}
                                        <MDBox mt={4} mb={1} style={{ alignSelf: 'center' }}>
                                            {subscription && subscription.length > 0 ?

                                                subscription[0].tier == 1 ?
                                                    subscription[0].price_id == tier.price_id ? <MDButton disabled={true} variant="gradient" color="info" fullWidth type="submit">
                                                        Subscribed
                                                    </MDButton> : <MDButton onClick={() => updateSubscription(subscription[0].subscription, tier.price_id)} variant="gradient" color="info" fullWidth type="submit">
                                                        Upgrade Now
                                                    </MDButton>
                                                    :
                                                    subscription[0].price_id == tier.price_id ? <MDButton disabled={true} variant="gradient" color="info" fullWidth type="submit">
                                                        Subscribed
                                                    </MDButton> : <MDButton disabled={true} variant="gradient" color="info" fullWidth type="submit">
                                                        Contact Us
                                                    </MDButton>

                                                :
                                                <MDButton onClick={() => handleButtonClick(tier.price_id)} variant="gradient" color="info" fullWidth type="submit">
                                                    Buy Now
                                                </MDButton>
                                            }
                                        </MDBox>
                                    </CardContent>
                                </Card>
                            )
                            )
                        )}
                    </Grid>
                )
                }
            </MDBox>
        </DashboardLayout>
    )
}
export default Packs;