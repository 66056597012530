

import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import { confirmPasswordReset, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase/index';
import { useFormik } from "formik";
import * as Yup from "yup";
import { notifySuccess } from "components/ToastifyCompo";
import { notifyError } from "components/ToastifyCompo";
import useMediaQuery from "hooks/MediaQuery";

function Basic() {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const oobCode = searchParams.get("oobCode");
    const formik = useFormik({
        initialValues: {
            newpassword: '',
            confirmpassword: '',
        },
        validationSchema: Yup.object({
            newpassword: Yup.string().required('New Password is required'),
            confirmpassword: Yup.string().required("Confirm Password is Required").oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            confirmPasswordReset(auth, oobCode, values.newpassword)
                .then(() => {
                    notifySuccess('Reset password Successfully');
                    navigate('/authentication/sign-in');
                    setLoading(false);
                })
                .catch(err => {
                    notifyError(`Error changing password: ${err.message}`);
                    setLoading(false);
                });
        }

    });

    return (  
        <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            {isMobile &&
                <div style={{ width: '40%', height: '100vh', backgroundColor: '#004051', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                    <MDTypography variant="h1" fontWeight="medium" color="white" mt={1}>
                        LOGO
                    </MDTypography>
                </div>
            }
            <div style={{width:isMobile ? '60%' : '90%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card style={{width:isMobile ? '60%' : '100%', margin: 'auto' }}>
                    <MDBox
                        variant="gradient"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                        style={{ backgroundColor: '#14a4a4' }}

                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Change Password
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                            <MDBox mb={2}>
                                <MDInput type="password" label="New Password" fullWidth id={'newpassword'} value={formik.values.newpassword} onChange={formik.handleChange} />
                                {formik.touched.newpassword && formik.errors.newpassword ? (
                                    <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.newpassword}</p>
                                ) : ""}
                            </MDBox>

                            <MDBox mb={2}>
                                <MDInput type="password" label="Confirm Password" fullWidth id={'confirmpassword'} value={formik.values.confirmpassword} onChange={formik.handleChange} />
                                {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                    <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.confirmpassword}</p>
                                ) : ""}
                            </MDBox>
                            <MDBox mt={4} mb={1}>
                                <MDButton loading={loading} variant="gradient" color="info" fullWidth type="submit">
                                    {loading ? 'Submitting...' : 'Submit'}
                                </MDButton>
                            </MDBox>
                        </MDBox>

                    </MDBox>
                </Card>
            </div>
        </div>
    );
}

export default Basic;
