import React,{useState} from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDTextField from 'components/MDTextField';
import MDSelect from 'components/MDSelect';
import { subscribed, current_brackets, bracket_completed} from 'Data/Data';
import { handleValueChange } from 'api/globle';
import useMediaQuery from 'hooks/MediaQuery';


const EmailsChainModals = ({title, data, setFilteredData, buttonName1, buttonName2}) =>{
  const isDesktop = useMediaQuery('(min-width: 950px)');
  const [openModal, setOpenModal]= useState(false);
  const [isClearButton, setIsClearButton] = useState(false);
  const [searchValues, setSearchValues] = useState({
  email: null,
  currentBracket: null,
  subscribed: null,
  bracketCompleted: null,
})

const handleChange = (e) => {
  const search = handleValueChange(e, searchValues );
  setSearchValues(search);
};

const filterData = () => {
  const { email, currentBracket, subscribed, bracketCompleted } = searchValues;

  const filtered = data.filter(item => {
    // Define the conditions for each search value
    const emailMatch = email ? item.email === email : true;
    const bracketMatch = currentBracket ? item.current === currentBracket : true;
    const subscribedMatch = subscribed ? String(item.isPurchaseComplete) === subscribed : true;
    const completedMatch = bracketCompleted ? String(item.is_complete) === bracketCompleted : true;
    
    // Check if all conditions are met
    return emailMatch && bracketMatch && subscribedMatch && completedMatch;
  });

  // Only set data if any search value is provided
  if (email || currentBracket || subscribed || bracketCompleted) {
      setFilteredData(filtered);
      setIsClearButton(true);
  }
};

const clearFilters = () => {
  setSearchValues({
    email: '',
    currentBracket: '',
    subscribed: '',
    bracketCompleted: ''
  });
  setFilteredData(data);
  setIsClearButton(false);
};

  return (
    <div>
      <div style={{display:"flex"}}>
      <FilterAltIcon fontSize="medium" color="white" style={{ cursor:"pointer", marginRight: "10px", backgroundColor:"#fff", fill:"#14a4a4", borderRadius:"8px", fontSize:"40px"}} onClick={()=>setOpenModal(true)}/>
      {isClearButton ? <Button onClick={clearFilters} style={{backgroundColor: "#fff", color:"#14a4a4"}}>Clear Filters</Button> : null}
      </div> 
      <Dialog open={openModal} onClose={()=>setOpenModal(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent >
          <div>
          <div style={{display: isDesktop ? "flex" : ""}}>
          <MDTextField name="email" label="Email" type="email" handlerChange={handleChange}/>
          <MDSelect name="currentBracket" label="Current Brackets" options={current_brackets} value={searchValues.currentBracket} handlerChange={handleChange}/>
          </div>
          
        <div style={{display: isDesktop ? "flex" : ""}}>
            <MDSelect name="subscribed" label="Subcribed" options={subscribed} value={searchValues.subscribed} handlerChange={handleChange}/>
            <MDSelect name="bracketCompleted" label="Bracket Completed" options={bracket_completed} value={searchValues.bracketCompleted} handlerChange={handleChange}/>
        </div>
        
        </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenModal(false);filterData()}} color="primary">
            {buttonName1}
          </Button>
          <Button onClick={()=>setOpenModal(false)} color="primary">
            {buttonName2}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default EmailsChainModals;
