/*eslint-disable */

import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { Allpacks } from "Data/Data";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { notifySuccess } from "components/ToastifyCompo";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext, useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getUcompingInvoice } from "api/stripe";
import { notifyError } from "components/ToastifyCompo";
import { getInvoices } from "api/stripe";
import { Context } from "context/userContext";
import { cancelSubscription } from "api/stripe";
import { useNavigate } from "react-router-dom";

const InvoiceTable = () => {
    const [invoice, setInvoice] = useState(null)
    const [pastInvoices, setPastInvoices] = useState([])
    const { user,isEmailVerified } = useContext(Context)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [load, setLoad] = useState(false)
    useEffect(() => {
        // console.log(user)
        if (user && user.cu_id) {
            if (user.stripe != null) {
                getUcompingInvoice(user.cu_id).then(e => {
                    console.log(e)
                    if (!e.status) {
                        notifyError("ERROR LOADING DATA")
                    }
                    else {
                        setInvoice(e.data)
                    }
                })
            }
            getInvoices(user.cu_id).then(e => {
                if (!e.status) {
                    notifyError("ERROR LOADING DATA")
                }
                else {
                    setPastInvoices(e.data)
                }
            })

        }

    }, [user])

    const cancelSubscripitions = () => {
        setLoad(true)
        cancelSubscription(user.email).then(e => {
            console.log(e)
            setLoad(false)
            window.location.reload()
        })
    }
    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
        </MDBox>
    );
    const columns = [
        { Header: "Invoice #", accessor: "invoicenum", align: "left", },
        { Header: "Amount", accessor: "amount", align: "left" },
        { Header: "Date", accessor: "date", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
    ]
    const rows = pastInvoices.map(inv => ({
        invoicenum: <Job title={inv.id} />,
        amount: <Job title={'$' + inv.amount / 100} />,
        date: <Job title={inv.date.split('T')[0]} />,
        action: (
            <MDTypography component="a" href={inv.invoice} color="text">
                <Tooltip title="View" arrow>
                    <VisibilityIcon fontSize="small" color="info" />
                </Tooltip>
            </MDTypography>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Dialog
                hideBackdrop
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open} onClose={() => setOpen(false)} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>Cancel Subscription Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to cancel your active subscription?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={load} onClick={() => setOpen(false)} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={cancelSubscripitions} style={{ color: 'blue' }} variant="outlined">
                        {load ? "please wait.." : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
                <MDTypography variant="h6" color="white">
                    Invoices
                </MDTypography>
                {invoice && <MDTypography variant="h6" color="white">
                    Next Invoice due on Date: {invoice.next_date_attempt.split('T')[0]}
                </MDTypography>}
                {!user ?
                    <Button variant="outlined" color="error" disabled>
                        Loading...
                    </Button>
                    : user.stripe == null ?
                        <Button variant="outlined" color="error" onClick={() => navigate("/calculatorpacks")} style={{
                            filter: !isEmailVerified ? 'blur(2px)' : 'none',
                            pointerEvents: !isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                        }}>
                            Buy Subscription
                        </Button>
                        : <Button variant="outlined" color="error" onClick={() => setOpen(true)}>
                            Cancel Subscription
                        </Button>}
            </MDBox>   
            <MDBox mt={4} mb={3} mx={1}
                py={2}
                px={2}

                style={{
                    filter: !isEmailVerified ? 'blur(2px)' : 'none',
                    pointerEvents: !isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                }}
            >
                <MDBox pt={1}>
                    <DataTable
                        table={{ columns: columns, rows: rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}
export default InvoiceTable;
