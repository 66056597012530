/*eslint-disable */
import { Button, Card, CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { Allpacks } from "Data/Data";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { notifySuccess } from "components/ToastifyCompo";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format, parse } from 'date-fns';
import CustomizedProgressBars from "components/Loader";
import {getFollowUp,sendPersonalisedEmail} from "api/adandoned";
import MDButton from "components/MDButton";
import EmailEditor from "components/admin/email-editor";
import { Context } from "context/userContext";
import { notifyError } from "components/ToastifyCompo";
import EmailsChainModals from "examples/Modals/EmailsChainModal/EmailsChainModal";


const Abandoned = () => {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [load, setLoad] = useState(false)
    const [open, setOpen] = useState(false)
    const { setTo,subject,emailContent } = useContext(Context)

    useEffect(() => {
        setLoad(true)
        getFollowUp().then((e) => {
            setData(e.data)
            setFilteredData(e.data);
            setLoad(false)
        })
    }, [])

    const navigate = useNavigate()


    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
                
            </MDTypography>
        </MDBox>
    );

    const columns = [
        { Header: "Id", accessor: "id", align: "left", },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Name", accessor: "fullName", align: "left" },
        { Header: "Current Bracket", accessor: "current_bracket", align: "center" },
        { Header: "Subscribed", accessor: "subscribed", align: "center" },
        { Header: "Date", accessor: "date", align: "center" },
        { Header: "Time", accessor: "time", align: "center" },
        { Header: "Bracket Completed", accessor: "bracket_completed", align: "center" },
        { Header: "Personalised SentAt", accessor: "personalised_sentAt", align: "center" },
        { Header: "Action", accessor: "action", align: "center" }
    ]

    const rows = filteredData.map((inv, index) => ({
        id: <Job title={index + 1} />,
        email: <Job title={inv.email} />,
        fullName: <Job title={inv.fullName} />,
        current_bracket: <Chip color="secondary" label={inv.current} />,
        subscribed: <Chip label={inv.isPurchaseComplete ? "Yes" : "No"} />,
        date: <Chip label={inv.sentAt.split(",")[0]} />,
        time: <Chip label={inv.sentAt.split(",")[1]} />,
        bracket_completed: <Chip label={inv.is_complete ? "Yes" : "No"} />,
        personalised_sentAt: <Chip label={inv.personalised_sentAt} />,
        action: inv.is_complete && !inv.isPurchaseComplete && (
            <MDButton onClick={() => handleOpen(inv.email)} variant="gradient" color="info" fullWidth type="submit">
                Send Email
            </MDButton>
        )
    }));


    
    const handleOpen = (email) => {
        setTo(email)
        setOpen(true)
    }

    const handleEmail =()=>{
        const body = {
            subject:subject,
            template:emailContent,
            email:"yahegiw298@luravel.com"
        }
        sendPersonalisedEmail(body).then(e=>{
            if(e.status){
                notifySuccess("Email was sent successfully.")
                setOpen(false)
            }
            else{
                notifyError("Couldn't send email.")
            }
        })
    }


    return (
<>
        <DashboardLayout>
            <Dialog
                hideBackdrop
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open} onClose={() => setOpen(false)} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Adjust the maximum width as per your requirement
                        },
                    },
                }}>
                <DialogTitle>personalised Email</DialogTitle>
                <DialogContent sx={{ minWidth: '400px', minHeight: '300px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: 1 }}>
                        <EmailEditor />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => handleEmail()} style={{ color: 'blue' }} variant="outlined">
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
            <DashboardNavbar />
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
                <MDTypography variant="h6" color="white">
                    Abandoned Emails Chain
                </MDTypography>
                <EmailsChainModals title="Filters" data={data} setFilteredData={setFilteredData} buttonName1="Search" buttonName2="Cancel"/>
                            
                </MDBox>
            {load ? <CustomizedProgressBars /> :
                <MDBox mt={4} mb={3} mx={1}
                    py={2}
                    px={2}>
                    <MDBox pt={1}>
                        <DataTable
                            table={{ columns: columns, rows: rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </MDBox>
                </MDBox>
            }
        </DashboardLayout>
      
        </>
    )
}
export default Abandoned;
