import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";

const allData = {
    pending: [
        { id: 1, name: 'User1', email: 'user1@example.com', package: 'Basic Package' },
        { id: 2, name: 'User2', email: 'user2@example.com', package: 'Standard Package' },
    ],
    upcoming: [
        { id: 3, name: 'User3', email: 'user3@example.com', package: 'Premium Package' },
    ],   
    completed: [
        { id: 4, name: 'User4', email: 'user4@example.com', package: 'Premium Plus Package' },
        { id: 5, name: 'User5', email: 'user5@example.com', package: 'Basic Package' },
    ],
};
const InvoiceAdmin = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);   
    };
    const getRows = (data) => data.map(inv => ({
        id: inv.id,    
        name: <MDTypography variant="body2">{inv.name}</MDTypography>,
        email: <MDTypography variant="body2">{inv.email}</MDTypography>,
        package: <MDTypography variant="body2">{inv.package}</MDTypography>,
        action: (      
            <Tooltip title="View" arrow>
                <VisibilityIcon fontSize="small" color="info" />
            </Tooltip>
        ),  
    }));     
    const columns = [
        { Header: "ID", accessor: "id", align: "left" },
        { Header: "Name", accessor: "name", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Package", accessor: "package", align: "center" },
        { Header: "Action", accessor: "action", align: "center" },
    ];     
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
                <MDTypography variant="h6" color="white">
                    Invoices   
                </MDTypography>
            </MDBox>
            <MDBox mt={3}>
                <Tabs value={selectedTab} onChange={handleChangeTab} centered sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: "transparent",  // Hides the default indicator
                    },
                    "& .MuiTab-root": {
                        "&.Mui-selected": {
                            color: '#fff',  // Change color for the active tab here
                            backgroundColor: '#14a4a4',  // Add if you need background color change
                        }
                    }
                }}> 
                    <Tab label="Pending" />
                    <Tab label="Upcoming" />   
                    <Tab label="Completed" />
                </Tabs>
                <MDBox pt={3}>
                    {selectedTab === 0 &&
                        <DataTable table={{ columns, rows: getRows(allData.pending) }} isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder />  
                    }
                    {selectedTab === 1 &&
                        <DataTable table={{ columns, rows: getRows(allData.upcoming) }} isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder />
                    }
                    {selectedTab === 2 &&
                        <DataTable table={{ columns, rows: getRows(allData.completed) }} isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder />
                    }
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
};
export default InvoiceAdmin;
