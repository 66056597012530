import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB4bvvYHuOgABy3IzeV8DYobcs48CXY7cs",
  authDomain: "monetise-d-staging.firebaseapp.com",
  projectId: "monetise-d-staging",
  storageBucket: "monetise-d-staging.appspot.com",
  messagingSenderId: "295224837425",
  appId: "1:205265896224:web:69756237572b96c54c8d45",
  measurementId: "G-P7DJXE62MX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
