import React, { useContext, useState } from 'react';
import { EditorState, RichUtils, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Grid, TextField } from '@mui/material';
import { Context } from 'context/userContext';
import { stateToHTML } from 'draft-js-export-html';

function EmailEditor() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const {to,setEmailContent,subject, setSubject} = useContext(Context)
  const [cc, setCC] = useState('');
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = editorState.getCurrentContent();
    // const rawContent = convertToRaw(contentState);
    const html = stateToHTML(contentState);
    console.log(html);  
    setEmailContent(html)
};
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
        onEditorStateChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <div style={{ border: '1px solid #ccc', minHeight: '400px', padding: '10px' }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="To:"
          value={to}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="CC:"
          value={cc}
          onChange={(e) => setCC(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Subject:"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          handleKeyCommand={handleKeyCommand}
        />
      </Grid>
    </Grid>
  </div>
  );
}

export default EmailEditor;
