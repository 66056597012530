/*eslint-disable */

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase/index';
import { useFormik } from "formik";
import * as Yup from "yup";
import { notifyError } from "components/ToastifyCompo";
import useMediaQuery from "hooks/MediaQuery";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const isMobile = useMediaQuery('(min-width: 950px)');

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      signInWithEmailAndPassword(auth, values.email, values.password).then(userrecord => {
        localStorage.setItem(userrecord.user.accessToken, "login")
        navigate('/')
        setLoading(false);
      }).catch(err => {
        notifyError(`Invalid Credentials`);
        setLoading(false);
      });
    }
  });

  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      {isMobile &&
        <div style={{ width: '40%', height: '100vh', backgroundColor: '#004051', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
          <MDTypography variant="h1" fontWeight="medium" color="white" mt={1}>
            LOGO
          </MDTypography>
        </div>
      }
      <div style={{ width:isMobile ? '60%' : '90%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Card style={{ width:isMobile ? '60%' : '100%', margin: 'auto' }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            style={{ backgroundColor: '#14a4a4' }}

          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput type="email" label="Email" fullWidth id={'email'} value={formik.values.email} onChange={formik.handleChange} />
              </MDBox>
              {formik.touched.email && formik.errors.email ? (
                <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.email}</p>
              ) : ""}
              <MDBox mb={2}>
                <MDInput type="password" label="Password" fullWidth id={'password'} value={formik.values.password} onChange={formik.handleChange} />
              </MDBox>
              {formik.touched.password && formik.errors.password ? (
                <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.password}</p>
              ) : ""}
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton loading={loading} variant="gradient" color="info" fullWidth type="submit">
                  {loading ? 'Signing in...' : 'Sign in'}
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient

                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Forget Password?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/reset-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient

                  >
                    Reset
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </div>
    </div>
  );
}

export default Basic;
