/*eslint-disable */

export const Allpacks =[
    {
        id:1,
        title:'Business Start-up Cost Calculator',
        iframe:'1dwihd79879879hdoiwhidohwo'
    },
    {
        id:2,
        title:'Yield Calculator',
        iframe:'2frah3u3ihu3'   

    },
    {
        id:3,
        title:'Goods and Services Calculator',
        iframe:'3hhuyuyuygbugyu'

    },
    {
        id:4,
        title:'Profit Growth Calculator',
        iframe:'4djwjdiwdwodj0wjd0wj'

    }
]

export const furtherIndustry = [
    {
        id:1,
        industry:'Financial Institutes',
        options:[
            {
                id:1,
                value:"Bank",
                label:"Bank",
                region:["NZ","AUS"]
            },
            {
                id:2,
                value:"Credit Union",
                label:"Credit Union",
                region:["NZ","AUS"]

            },
            {
                id:3,
                value:"Commercial Lender",
                label:"Commercial Lender",
                region:["NZ","AUS"]

            }
        ]
    },
    {
        id:2,
        industry:'Accountants',
        options:[
            {
                id:1,
                value:"Tier 1",
                label:"Tier 1",
                region:["USA","UK","NZ","AUS"]


            },
            {
                id:2,
                value:"Mid Tier",
                label:"Mid Tier",
                region:["USA","UK","NZ","AUS"]

            },
            {
                id:3,
                value:"Boutique",
                label:"Boutique",
                region:["USA","UK","NZ","AUS"]

            }
        ]
    },
    {
        id:3,
        industry:'Financial Adviser',
        options:[
             {
                id:1,
                value:"Financial Planner Single Office",
                label:"Financial Planner Single Office",
                region:["AUS"]
            },
            {
               id:2,
               value:"Financial Planner Single Office",
               label:"Financial Adviser",
               region:["NZ"]
           },
            {
               id:3,
               value:"Financial Planner Single Office",
               label:"Financial Planner",
               region:["USA"]
           },
           {
              id:4,
              value:"Financial Planner Single Office",
              label:"Financial Planning",
              region:["UK"]
          }
            // {
            //     id:1,
            //     value:"Superannuation",
            //     label:"Superannuation"

            // },
            // {
            //     id:2,
            //     value:"Multi-office",
            //     label:"Multi-office"

            // },
            // {
            //     id:3,
            //     value:"Boutique",
            //     label:"Boutique"

            // }
        ]
    }
    ,
    {
        id:4,
        industry:'Real Estate Agencies',
        options:[
            {
                id:1,
                value:"Multi-office",
                label:"Multi-office"

            },
            {
                id:2,
                value:"single-office",
                label:"single-office"

            }
        ]
    },
    {
        id:5,
        industry:'Law',
        options:[
            {
                id:1,
                value:"Property Law",
                label:"Property Law",
                region:["AUS"]

            },
            {
                id:2,
                value:"Patent Attorney",
                label:"Patent Attorney",
                region:["AUS","USA","UK","NZ"]

            }
        ]
    },
    {
        id:5,
        industry:'Real Estate',
        options:[
            {
                id:1,
                value:"Single Office",
                label:"Single Office",
                region:["AUS" , "NZ"]

            }
        ]
    },
    // {
    //     id:6,
    //     industry:'Real Estate',
    //     options:[
    //         {
    //             id:1,
    //             value:"Single Office",
    //             label:"Single Office",
    //             region:["AUS" , "NZ"]

    //         }
    //     ]
    // }
]

export const Region = [
    {
        id:1,
        value:"NZ",
        label:"New Zeland"

    },
    {
        id:2,
        value:"AUS",
        label:"Australia"

    },
    {
        id:3,
        value:"USA",
        label:"USA/Canada"

    },
    {
        id:4,
        value:"UK",
        label:"United Kingdom"

    }
]
                      
export const Industrydata =[
    {
        id:1,
        value:"Financial Institutes",
        label:"Financial Institutes",
        region:["AUS","NZ"]
    },
    {
        id:2,
        value:"Accountants",
        label:"Accountants",
        region:["AUS", "NZ",'USA',"UK"]

    },
    {
        id:4,
        value:"Financial Adviser",
        label:"Financial Adviser",
        region:["AUS", "NZ",'USA',"UK"]

    },
    {
        id:5,
        value:"Law",
        label:"Law",
        region:["AUS", "n\pNZ",'USA',"UK"]

    },
    {
        id:6,
        value:"Real Estate",
        label:"Real Estate",
        region:["AUS"]

    }
    // ,
    // {
    //     id:7,
    //     value:"Investment",
    //     label:"Investment"
    // }
]

export const subscribed = [
    {
        id: 1,
        value: "true",
        label: "Yes"
    },
    {
        id:2,
        value: "false",
        label: "No"
    }
]
export const current_brackets = [
    {
        id: 1,
        value: "first_bracket",
        label: "First Bracket"
    },
    {
        id:2,
        value: "second_bracket",
        label: "Second Bracket"
    }
]

export const bracket_completed = [
    {
        id: 1,
        value: true,
        label: "Yes"
    },
    {
        id:2,
        value: false,
        label: "No"
    }
]

export const email_verified = [
    {
        value: true,
        label: "Verified",
    },
    {
        value: false,
        label: "Not Verified",
    }
]