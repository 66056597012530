/*eslint-disable */

import { useCallback } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const logout = useCallback(() => {
    auth.signOut()
      .then(() => {
        // alert('User logged out');
        navigate("/authentication/sign-in");
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  }, [auth, navigate]);

  return logout;
};

export default useLogout;
