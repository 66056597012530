/*eslint-disable */

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { Allpacks } from "Data/Data";
import MDBox from "components/MDBox";
import { SketchPicker } from 'react-color'
import MDTypography from "components/MDTypography";
import { notifySuccess } from "components/ToastifyCompo";
import { Context } from "context/userContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext, useEffect, useState } from "react";
import { updatePersonalisation } from "api/user";
import { notifyError } from "components/ToastifyCompo";
import MDInput from "components/MDInput";

const Personalization = () => {
    const { user,isEmailVerified } = useContext(Context)
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [color, setColor] = useState("DEFAULT")
    const [colorS, setColorS] = useState("DEFAULT")
    const [note, setNote] = useState("NO DISCLAIMER ADDED")
    const [url, setUrl] = useState("#")
    const defaultDisclaimer = "Results provided by this tool are to be deemed as guide only. Your use of this tool and your reliance on any information on the site is solely at your own risk. This tool is not intended to be a substitute for professional advice. Always seek the advice of a qualified professional with any questions you may have regarding a financial or legal situation."
    const [isDefaultDisclaimer, setIsDefaultDiscalimer]= useState(false);
    const [editedNote, setEditedNote] = useState(""); // State to hold the edited note
    const [TYPE, setType] = useState("")
    const [load, setLoad] = useState(false)
    // console.log(user)
    const addPersonalisation = () => {
        setLoad(true)
        const body = {
            primary: color,
            secondary: colorS,
            note:  editedNote,
            email: user.email
        }
        updatePersonalisation(body).then(e => {
            if (e.status) {
                window.location.reload()
            }
            else {
                notifyError(e.message)
            }
            setLoad(false)
            setOpen(false)

        })
    }
    useEffect(() => {
        if (user) {
            if (user.personalised) {

                setColor(user.personalised.primary)
                setColorS(user.personalised.secondary)
                setNote(user.personalised.note)
        setEditedNote(user.personalised.note);

            }
        }
    }, [user])

    useEffect(()=>{
        if(isDefaultDisclaimer){
            setEditedNote(defaultDisclaimer);
            setIsDefaultDiscalimer(!isDefaultDisclaimer);
        }
    },[isDefaultDisclaimer])


    const handleCancelClick = ()=>{
        setOpen1(false);
        setEditedNote(note);
    }
    const copyToClipboard = (iframe) => {
        navigator.clipboard.writeText(iframe).then(() => {
            notifySuccess("Iframe copied to clipboard!"); // Optionally, replace with a more subtle notification
        });
    };
    const Job = ({ title }) => {
        const maxLength = 50;
              const truncateText = (text, maxLength) => {
          return text.length > maxLength ? `${text.slice(0, maxLength)}......` : text;
        };
      
        return (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography
              display="block"
              variant="caption"
              color="text"
              fontWeight="medium"
              sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                width: "100%",
              }}
            >
              {truncateText(title, maxLength)}
            </MDTypography>
          </MDBox>
        );
      };
      
    const columns = [
        { Header: "No", accessor: "No", align: "left", width: '5%' },
        { Header: "Name", accessor: "Name", align: "left", },
        { Header: "Code", accessor: "Code", align: "left" },
        { Header: "action", accessor: "action", align: "center" },

    ]
    const rows = [
        {
            No: <Job title={1} />,
            Name: <Job title={"primary"} />,
            Code: <Job title={color} />,
            action: (
                <Button
                    key={1}
                    onClick={() => {
                        setType("primary")
                        setOpen(true)
                    }}
                    sx={{
                        backgroundColor: "grey",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#14a4a4",
                            color: "#fff",

                        }
                    }}
                >
                    Change
                </Button>
            )
        },   
        {
            No: <Job title={2} />,
            Name: <Job title={"secondary"} />,
            Code: <Job title={colorS} />,
            action: (
                <Button
                    key={2}
                    onClick={() => {
                        setType("secondary")
                        setOpen(true)
                    }}
                    sx={{
                        backgroundColor: "grey",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#14a4a4",
                            color: "#fff",

                        }
                    }}
                >
                    Change
                </Button>
            )
        }
    ]
    const columnsDis = [
        { Header: "Note", accessor: "Note", align: "left" },
        { Header: "action", accessor: "action", align: "center" },

    ]
    const rowsDis = [
        {
            Note: <Job title={note} />,
            action: (
                <Button
                    key={1}
                    onClick={() => {
                        setOpen1(true)
                    }}
                    sx={{
                        backgroundColor: "grey",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#14a4a4",
                            color: "#fff",

                        }
                    }}
                >
                    Edit Note
                </Button>
            )
        },
    ]

    const columnsUrl = [
        { Header: "Url", accessor: "Url", align: "left" },
        { Header: "", accessor: "actions", align: "center" },
        { Header: "action", accessor: "action", align: "center" },

    ]
    const rowsUrl = [
        {
            Url: <Job title={url} />,
            action: (
                <Button
                    key={1}
                    onClick={() => {
                        setOpen2(true)
                    }}
                    sx={{
                        backgroundColor: "grey",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#14a4a4",
                            color: "#fff",

                        }
                    }}
                >
                    Edit URL
                </Button>
            )
        },
    ]
    return (
        <DashboardLayout>

            <Dialog
                hideBackdrop
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open} onClose={() => setOpen(false)} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>Configure your Calculator styles</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Configure your {TYPE} color.
                    </DialogContentText>
                    <SketchPicker
                        color={TYPE == "primary" ? color : colorS}
                        onChange={(color) => TYPE == "primary" ? setColor(color.hex.substring(1)) : setColorS(color.hex.substring(1))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={load} onClick={() => setOpen(false)} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={addPersonalisation} style={{ color: 'blue' }} variant="outlined">
                        {load ? "Please wait..." : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* EditNote */}
            <Dialog
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open1} 
                // onClose={() => setOpen1(false)} 
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>Edit Note for Calculator</DialogTitle>
                <DialogContent>
                    <Button onClick={()=>setIsDefaultDiscalimer(true)}>Use Default Disclamier</Button>
                    <DialogContentText>
                        Note:
                    </DialogContentText>
                    <TextField
                        id="outlined-multiline-static"
                        label=""
                        multiline
                        rows={4}
                        defaultValue={note}
                        fullWidth
                        value={editedNote} // Use editedNote if it's not empty, otherwise use note
                        onChange={(e) => setEditedNote(e.target.value)} // Update edited note state
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={load} onClick={handleCancelClick} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={addPersonalisation} style={{ color: 'blue' }} variant="outlined">
                        {load ? "Please wait..." : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* EditWebsiteURL */}
            <Dialog
                slotProps={{ backdrop: false }}
                disableEscapeKeyDown // Disable clickaway behavior
                open={open2} 
                // onClose={() => setOpen2(false)} 
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                <DialogTitle>Edit Contact URL</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Contact Page:
                    </DialogContentText>
                    <TextField
                        id="outlined-multiline-static"
                        label=""
                        multiline
                        rows={4}
                        defaultValue={note}
                        fullWidth
                        value={url} // Use editedNote if it's not empty, otherwise use note
                        onChange={(e) => setUrl(e.target.value)} // Update edited note state
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={load} onClick={() => setOpen2(false)} style={{ color: 'blue' }} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={() => { }} style={{ color: 'blue' }} variant="outlined">
                        {load ? "Please wait..." : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
            <DashboardNavbar />
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Personalised Settings
                </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={3} mx={1}
                py={2}
                px={2}
                style={{
                    filter: !isEmailVerified ? 'blur(2px)' : 'none',
                    pointerEvents: ! isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                }}
                >
                <MDBox pt={1}>
                    <DataTable
                        table={{ columns: columns, rows: rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Disclamier
                </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={3} mx={1}
                py={2}
                px={2}
                style={{
                    filter: !isEmailVerified ? 'blur(2px)' : 'none',
                    pointerEvents: ! isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                }}
                >
                <MDBox pt={1}>
                    <DataTable
                        table={{ columns: columnsDis, rows: rowsDis }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Get in Touch
                </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={3} mx={1}
                py={2}
                px={2}
                style={{
                    filter: !isEmailVerified ? 'blur(2px)' : 'none',
                    pointerEvents: ! isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
                }}
                >
                <MDBox pt={1}>
                    <DataTable
                        table={{ columns: columnsUrl, rows: rowsUrl }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}
export default Personalization;