import axios from "axios";
import { BASE_URL } from "context/userContext";
const URL = BASE_URL+ '/auth'
export async function addUserRecord(body){
    try {
        const response =  await axios.post(URL+"/add",body)
        return response.data
    } catch (error) {
        console.log(error.response.data)
        return error.response.data
    }
}
export async function getMe(email){
    try {
        const response =  await axios.post(URL+"/get",{email:email})
        return response.data
    } catch (error) {
        return error.response.data
    }
}

export async function sendVerifyEmail(email){
    try {
        const response =  await axios.post(URL+"/send-verification-email",{
            userEmail:email,
            redirectUrl:"http://localhost:3000"
        })
        console.log(response.data)
        return response.data
    } catch (error) {
        console.log(error.response.data)
        return error.response.data
    }
}

export async function updatePersonalisation(body){
    try {
        const response =  await axios.post(URL+"/update",body)
        return response.data
    } catch (error) {
        return error.response.data   
    }
}

// Admin
export async function GetUsers(){
    try {
        const response =  await axios.get(URL+"/listallusers")
        return response.data
    } catch (error) {
        return error.response.data   
    }
}

export async function GetSubscription(){
    try {
        const response =  await axios.get(URL+"/getSubscribedUsers")
        return response.data
    } catch (error) {
        return error.response.data   
    }
}

export async function updateUserInfo(body){
    try {
        const response =  await axios.post(URL+"/info",body)
        return response.data
    } catch (error) {
        return error.response.data   
    }
}

export async function updateHasClicked(body){
    console.log(body,"updateclick")
    try {
        const response =  await axios.post(URL+"/has-clicked",{email:body})
        return response.data
    } catch (error) {
        return error.response.data   
    }
}

