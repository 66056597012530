import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {FormControl, Select, InputLabel} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const MDSelect = ({ label, options, value, handlerChange,name }) => {
    const classes = useStyles();
  return (
    <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
              <Select
          native
          value={value}
          onChange={handlerChange}
          label={label}
          inputProps={{
            name: name,
            id: 'outlined-age-native-simple',
          }}
        >
            <option aria-label="None" value="" />
          {
            options && options.map((item)=>{
                return(
<option key={item.id} value={item.value}>{item.label}</option>
            )})
          }
        </Select>
            </FormControl>

          
    </div>
  )
}

export default MDSelect;
