import axios from "axios"
import { BASE_URL } from "context/userContext"
const URL = BASE_URL+'/stripe'
export async function createSubscription(priceId,email){
    try{
        const response = await axios.post(URL+'/create-checkout',{
            priceId:priceId,
            email:email
        })
        return response.data
    }
    catch(err){
        return err.response.data
    }
}
export async function getSession(session_id,email){
    console.log(session_id,email,"getsession")
    try{
        const response = await axios.get(URL+`/get-session?session_id=${session_id}&email=${email}`)
        return response.data
    }
    catch(err){
        return err.response.data
    }
}

export async function getUcompingInvoice(customer){
    try {
        const response  =  await axios.get(URL+`/get-invoice/upcoming?customer=${customer}`)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
export async function getInvoices(customer){
    try {
        const response  =  await axios.get(URL+`/get-invoice?customer=${customer}`)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
export async function getSubscription(email){
    try {
        const response  =  await axios.post(URL+'/get-subscription',{email:email})
        return response.data
    } catch (error) {
        return error.response.data
    }
}

export async function upgradeSubscription(subscription,price,email){
    try {
        const response  =  await axios.post(URL+'/subscription/update',{subscription:subscription,price_id:price,email:email})
        return response.data
    } catch (error) {
        return error.response.data
    }
}

export async function cancelSubscription(email){
    try {
        const response = await axios.get(URL+`/get-invoice/cancel?email=${email}`)
        return response.data
    } catch (error) {
        return error.response.data
    }
}