import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '21.1ch',
    },
  },
}));

const MDTextFields = ({label, value, type, handlerChange,name}) => {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      
      <TextField name={name} id="outlined-search" label={label} value={value} type={type} variant="outlined" onChange={handlerChange}/>
      
    </form>
  );
}

export default MDTextFields;