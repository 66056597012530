import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom"
// import { initializeApp } from "firebase/app";
// import { getAuth , applyActionCode } from "firebase/auth";
// import { checkActionCode, sendPasswordResetEmail } from "firebase/auth";
// import {auth} from "../../
import { auth } from "../../../../firebase/index";
import { applyActionCode } from 'firebase/auth';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const Verify = () => {



  const [show, setShow] = useState(false)
  const location = useLocation()
  const params = useParams()
  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false)
  const path = location.pathname.split("?")
  const getParameterByName = new URLSearchParams(window.location.search)
  // const mode = getParameterByName.get("mode")   
  const mode = getParameterByName.get('mode');
  // Get the one-time code from the query parameter.
  const actionCode = getParameterByName.get('oobCode');
  // (Optional) Get the continue URL from the query parameter if available.  
  const continueUrl = getParameterByName.get('continueUrl');
  // (Optional) Get the language code if available.
  const lang = getParameterByName.get('lang') || 'en';
  const config = {
    'apiKey': "AIzaSyALXeSKATS_wXFg6SX5UUvMx0aGgh7bosQ" // Copy this key from the web initialization
    // snippet found in the Firebase console.
  };


  useEffect(() => {


    handleVerifyEmail(auth, actionCode)


  }, [])

  function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
    console.log(actionCode, mode)
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    applyActionCode(auth, actionCode).then((resp) => {
      console.log(resp, "ress")

      // Email address has been verified.
      setMessage("Your email has been verified")
      //   localStorage.setItem("emailVerified","1")
      //  window.close()  
      setOpen(true)
    }).catch((error) => {
      console.log(error, "error")

      setMessage("Verification link expired or the email has already been verified.")
      setOpen(true)

      // Code is invalid or expired. Ask the user to verify their email address
      // again.
    });
  }
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  return (
    <>
      <Dialog
        hideBackdrop
        slotProps={{ backdrop: false }}
        disableEscapeKeyDown // Disable clickaway behavior
        open={open} onClose={() => setOpen(false)} sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",  // Set your width here
            },
          },
        }}>
        <DialogTitle>Verify your email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>closeTab()} style={{ color: 'blue' }} variant="outlined">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Verify