/*eslint-disable */

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase/index';
import { useFormik } from "formik";
import * as Yup from "yup";
import { notifySuccess } from "components/ToastifyCompo";
import { notifyError } from "components/ToastifyCompo";
import useMediaQuery from "hooks/MediaQuery";

function Basic() {
  const isMobile = useMediaQuery('(min-width: 950px)');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    }),   
    onSubmit: async (values) => {
      setLoading(true);
      sendPasswordResetEmail(auth, values.email)
        .then(() => {
          notifySuccess('Reset password email sent successfully. Please check your email.');
          navigate('/authentication/sign-in');
          setLoading(false);
        })
        .catch(err => {
          notifyError(`Error sending reset email: ${err.message}`);
          setLoading(false);
        });
    }

  });

  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      {isMobile &&
        <div style={{ width: '40%', height: '100vh', backgroundColor: '#004051', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
          <MDTypography variant="h1" fontWeight="medium" color="white" mt={1}>
            LOGO
          </MDTypography>
        </div>
      }
      <div style={{ width: isMobile ? '60%' : '90%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Card style={{ width: isMobile ? '60%' : '100%', margin: 'auto' }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            style={{ backgroundColor: '#14a4a4' }}

          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput type="email" label="Email" fullWidth id={'email'} value={formik.values.email} onChange={formik.handleChange} />
              </MDBox>
              {formik.touched.email && formik.errors.email ? (
                <p style={{ color: 'red', fontSize: '12px' }}>{formik.errors.email}</p>
              ) : ""}
              <MDBox mt={4} mb={1}>
                <MDButton loading={loading} variant="gradient" color="info" fullWidth type="submit">
                  {loading ? 'Submitting...' : 'Submit'}
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Remember Password?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient

                >
                  Login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </div>
    </div>
  );
}

export default Basic;
