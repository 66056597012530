/*eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
// Data
import { GetUsers } from "api/user";

function Projects() {
  const [data, setData] = useState([])
  useEffect(() => {
    GetUsers().then((e) => {
      console.log(e, "e.data")
      setData(e.slice(0, 10));
    })
  }, [])
  const [load, setLoad] = useState(false)

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "Id", accessor: "id", align: "left", },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Verify", accessor: "verify", align: "left" },
    { Header: "Date", accessor: "date", align: "center" },
  ]
  const rows = data.map((inv, index) => ({
    id: <Job title={index + 1} />,
    email: <Job title={inv.email} />,
    verify: <Job title={inv.emailVerified === true ? 'Verified' : 'Not Verified'} />,
    date: <Job title={inv.metadata.creationTime} />
  }));   
  return (
    <Card>   
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            New Users
          </MDTypography>   
        </MDBox>   
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Projects;
