/*eslint-disable */

import { Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { notifySuccess } from "components/ToastifyCompo";
import { Context } from "context/userContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useContext } from "react";
import IframeResizer from 'iframe-resizer-react';

const Calculators = () => {
    const { user,isEmailVerified } = useContext(Context);

    const copyToClipboard = (iframeString) => {
        navigator.clipboard.writeText(iframeString).then(() => {
            notifySuccess("Iframe copied to clipboard!");
        });
    };

    const Job = ({ title }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
        </MDBox>
    );

    const columns = [
        { Header: "No", accessor: "No", align: "left", width: '5%' },
        { Header: "Name", accessor: "Name", align: "left" },
        { Header: "Iframe", accessor: "Iframe", align: "left" },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = (user && user.permissions) ? user.permissions.permissions.map((pack, index) => {
        const iframeString = `<iframe src="https://monetise-d-7bfcbe7273db.herokuapp.com${pack.path}?token=${user.permissions.apiKey}" style="width:100%; border:none;" frameborder="0" allowfullscreen></iframe>`;
        
        return {
            No: <Job title={index + 1} />,
            Name: <Job title={pack.name} />,
            Iframe: <Job title={"**********"} />,
            action: (
                <Button
                    key={index}
                    onClick={() => copyToClipboard(iframeString)}
                    sx={{
                        backgroundColor: "grey",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#14a4a4",
                            color: "#fff",
                        }
                    }}
                >
                    Copy
                </Button>
            )
        };
    }) : [];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    My Calculators
                </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={3} mx={1} py={2} px={2}
            style={{
                filter: !isEmailVerified ? 'blur(2px)' : 'none',
                pointerEvents: ! isEmailVerified ? 'none' : 'auto'  // Optional: to prevent interactions with blurred content
            }}
            >
                <MDBox pt={1}>
                    <DataTable
                        table={{ columns: columns, rows: rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
};

export default Calculators;
