/*eslint-disable */
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";
import OtpCode from "layouts/authentication/OtpCode";
import ChangePassword from "layouts/authentication/ChangePassword";


// @mui icons
import Icon from "@mui/material/Icon";
import Packs from "layouts/packs/Packs";
import Apikey from "layouts/API Management/apikey";
import Calculators from "layouts/API Management/calculators";
import InvoiceTable from "layouts/Invoice/InoiceTable";
import { auth, logout } from "./firebase/index";
// import LogoutIc from "components/LogoutIc";
import Personalization from "layouts/Personalization";
import Allusers from "layouts/users/Allusers";
import Subscriptions from "layouts/subscriptions/Subscription";
import InvoiceAdmin from "layouts/InvoiceAdmin/InvoiceAdmin";
import Verify from "layouts/authentication/OtpCode/Verify";
import Abandoned from "layouts/users/Abandoned";
                                        
const routes = [
 
  {
    type: "collapse",
    name: "Calculator Packs",
    key: "calculatorpacks",
    icon: <Icon fontSize="small">inventoryIcon</Icon>,
    route: "/calculatorpacks",
    component: <Packs />,
    isAdmin: false
  },
  {
    type: "collapse",
    name: "Your Api Key",
    key: "apikey",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/apikey",
    component: <Apikey />,
    isAdmin: false
  },
  {
    type: "collapse",
    name: "Calculators",
    key: "calculators",
    icon: <Icon fontSize="small">calculate</Icon>,
    route: "/calculators",
    component: <Calculators />,
    isAdmin: false
  },
  {
    type: "collapse",
    name: "Personalization",
    key: "settings",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/settings",
    component: <Personalization />,
    isAdmin: false
  },
  {
    type: "collapse",
    name: "Invoice",
    key: "invoice",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/invoice",
    component: <InvoiceTable />,
    isAdmin: false
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    isAdmin: true
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    isAdmin: false
  },
  // {
  //   type: "collapse",
  //   name: "Logout",
  //   key: "logout",
  //   icon: <Icon fontSize="small">logout</Icon>,
  //   route: "/logout",
  //   component: <LogoutIc />,
  //   isAdmin: false
  // },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    isAdmin: false
  },
  {
    name: "Verify Email",
    key: "verify",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/verify",
    component: <Verify/>,
    isAdmin: false
  },
  {
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">Reset Password</Icon>,
    route: "/authentication/reset-password",
    component: <ResetPassword />,
    isAdmin: false
  },
  {
    name: "Change Password",
    key: "change-password",
    icon: <Icon fontSize="small">Change Password</Icon>,
    route: "/authentication/change-password",
    component: <ChangePassword />,
    isAdmin: false
  },
  {
    name: "Otpcode",
    key: "otp-code",
    icon: <Icon fontSize="small">OTP</Icon>,
    route: "/authentication/otp-code",
    component: <OtpCode />,
    isAdmin: false
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    isAdmin: false
  },
  // SuperAdmin routes...
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    isAdmin: true
  },
  {
    type: "collapse",
    name: "Emails Chain",
    key: "emails-chain",
    icon: <Icon fontSize="small">envelope</Icon>,
    route: "/emails-chain",
    component: <Abandoned/>,
    isAdmin: true
  },
  {
    type: "collapse",
    name: "Allusers",
    key: "allusers",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/allusers",
    component: <Allusers />,
    isAdmin: true
  },
  {
    type: "collapse",
    name: "Subscriptions",
    key: "subscriptions",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subscriptions",
    component: <Subscriptions />,
    isAdmin: true
  },
  {
    type: "collapse",
    key: 'invoices',
    name: 'Invoices',
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/invoices",
    component: <InvoiceAdmin />,
    isAdmin: true
  },
  // {
  //   type: "collapse",
  //   name: "Logout",
  //   key: "logout",
  //   icon: <Icon fontSize="small">logout</Icon>,
  //   route: "/logout",
  //   component: <LogoutIc />,
  //   isAdmin: true
  // }
];



export default routes;

// test =  pk_test_51OkOoXDG6rDK4SK2GjbH1emRXbAeI2TBMNRegUxlKE3anRw7g2jkoo3nwHmpQwD61QFIysZBS61ORJWmrENnay9800R0Ql1S2g
// secrtet =sk_test_51OkOoXDG6rDK4SK2qxp2wHUNfqZp9oFZLwrVahc8osq56w0mMlpczGBRaTQi0sKK6mTvoo9nbf562JaJ7jy1YvYU00HQOAmpu1
